import { initialPrompt } from './sharedSelfService'
import gp_prompts from './doctorSelfService'
import dentistry_prompts from './dentistrySelfService'

export const GP_WORKFLOW = "GP_WORKFLOW"
export const DENTIST_WORKFLOW = "DENTIST_WORKFLOW"

const WORKFLOWS = {
    GP_WORKFLOW: {
        "prompts":gp_prompts,
        "initialPrompt": initialPrompt,
        "type": "medical",
        "arrayFields": [
            'family_medical_conditions_list',
        ]
    },
    DENTIST_WORKFLOW:{
        "prompts": dentistry_prompts,
        "initialPrompt": initialPrompt,
        "type": "dentistry",
        "arrayFields": [
            'allergies',
            'medical_conditions',
            'medical_condition_dates',
            'surgical_procedures',
            'surgical_procedure_dates',
            'family_medical_conditions_list',
        ]
    }
};

export default WORKFLOWS;