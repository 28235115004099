import React, {useState} from "react";
import {useHistory} from "react-router-dom";
import ConversationUI from "@a2d24/conversation-ui";
import {Backdrop} from '@mui/material';
import {CircularProgress} from '@mui/material';
import workflows, {GP_WORKFLOW, DENTIST_WORKFLOW} from "../workflows/workflows";
import logo from "../assets/logo.png";
import people from "../assets/people.svg";
import useStyles, {backdropStyles} from "./styles";
import axios from "axios";
import ErrorPopup from "../components/ErrorPopup";
import Background from "../components/Background";


const API_URL = process.env.REACT_APP_API_ENDPOINT;

const SelfService = () => {
    const classes = useStyles();
    const history = useHistory();
    let results = {}
    const [loading, setLoading] = useState(false);
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [workflow, setWorkflow] = useState(false);

    function clearEmpties(o) {
        for (const k in o) {
            if (!o[k] || typeof o[k] !== "object") {
                continue // If null or not an object, skip to the next iteration
            }

            // The property is an object
            if (Object.keys(o[k]).length === 0) {
                delete o[k]; // The object had no properties, so delete that property
            }
        }
    }


    if (loading) return <Backdrop
        sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
        open={loading}
    >

        <CircularProgress color="inherit"/>
    </Backdrop>

    const map_results_to_payload = (results) => {
        let prompt_map = {}
        workflows[workflow]?.prompts?.forEach((item) => {
            prompt_map[item.id] = item.section
        })
        let payload = {}
        workflows[workflow]?.prompts?.forEach((item) => {
            if (!(item.section in payload)) {
                payload[item.section] = {}
            }
            if (results[item.id]) payload[item.section][item.id] = results[item.id];

            if (item.onPayload) item.onPayload(results, workflows[workflow], payload)

        })
        clearEmpties(payload)
        payload.self_service_type = workflows[workflow].type
        return payload
    }

    const onComplete = async () => {
        const payload = map_results_to_payload(results);
        const referenceNumber = await submit(payload);
        if (referenceNumber) {
            const patientName = results.patient_first_name;
            const redirectPath = patientName ? `/${referenceNumber}?name=${patientName}` : `/${referenceNumber}`;
            history.push(redirectPath);
        }
        // TODO: handle error
        else {
            setLoading(false)
            setOpenErrorPopup(true)
            setWorkflow(false)
        }

    }

    const submit = async (payload) => {
        setLoading(true)
        try {
            const response = await axios.post(
                `${API_URL}/SelfService/create_submission`,
                {self_service_payload: payload}
            )
            setLoading(false)
            return response.data

        } catch (error) {
            return false;

        }

    }

    const handleResponse = (response, promptId, ctrl) => {
        if (response === undefined) {
            return;
        }

        const prompt = workflows[workflow]?.prompts.find(item => item.id === promptId)

        // use nullOnSkip to bypass the undefined check, then use nullAlias to replace the null if required
        if (response === null && prompt && prompt.config.nullAlias !== undefined) {
            response = prompt.config.nullAlias
        }

        if (workflows[workflow]?.arrayFields?.includes(promptId)) {
            if (Array.isArray(results[promptId])) {
                 if (response !== undefined) results[promptId].push(response);
            } else {
                if (response !== undefined) results[promptId] = [response];
                else results[promptId] = [];
            }
        } else {
            results[promptId] = response;
        }
    }
    
    return (
        <div className={!workflow ? classes.home : classes.root}>
            <ErrorPopup isOpen={openErrorPopup} setIsOpen={setOpenErrorPopup}/>
            <GetStartedScreen show={!workflow} setWorkflow={setWorkflow} />
            {workflow && <ConversationUI
                prompts={workflows[workflow]?.prompts}
                initialPrompt={workflows[workflow]?.initialPrompt}
                onComplete={onComplete}
                onResponse={handleResponse}
                logo={logo}
                messageProps={workflows[workflow]}
            />}
        </div>
    )
}

const GetStartedScreen = ({ show, setWorkflow }) => {
    const styles = backdropStyles();
    return (
        show && 
        <div className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title} >Welcome to the future of healthcare</div>
                <div className={styles.subtitle} >You can complete key information ahead of your medical consultation, right here!</div>
                <img alt="" src={people} className={styles.people} />
                <button className={`${styles.button} ${styles.button_one}`} onClick={() => setWorkflow(GP_WORKFLOW)}>I am here for a GP consult</button>
                <button className={`${styles.button} ${styles.button_two}`} onClick={() => setWorkflow(DENTIST_WORKFLOW)}>I am here for a dentist consult</button>
            </div>
            <Background showAngled={false} className={styles.background} />
        </div>
    )
}

export default SelfService;