import {PROMPT_IDS} from "./consts/promptIds";
import {
    allergies,
    chronicMedication,
    disabilities,
    familyHistory, generalInformation,
    lifestyleHistory,
    medicalCondition,
    patientHistory,
    personalInformation,
    profilePhoto,
    surgicalProcedures,
    userValidation
} from "./sharedSelfService";


// Order in Heal Website

// 1. Medical Conditions
// 2. Allergies / Drug intolerance
// 3. Disablities
// 4. Surgical Procedures
// 5. Chronic Medicines
// 6. Lifestyle History
// 7. Family History
// 8. Occupational and Social History
// 9. Vaccines
// 10. Gender Specific Conditions
// 11. Family Structure
// 12. Support Structure
// 13. Spirituality
// 14. Hobbies
// 15. General

const prompts = [
    // Patient Information
    ...userValidation,
    ...profilePhoto,
    ...patientHistory,

    // Medical and Contextual History
    ...allergies(PROMPT_IDS.DISABILITIES),
    ...disabilities,
    ...medicalCondition,
    ...surgicalProcedures,
    ...chronicMedication(PROMPT_IDS.SMOKING),
    ...lifestyleHistory,
    ...familyHistory(PROMPT_IDS.OCCUPATIONAL_AND_SOCIAL_HISTORY),
    ...personalInformation,
    ...generalInformation(null)

    // ...vaccinations,
    // ...adultFemaleMedicalHistory,
    // ...adultMaleMedicalHistory
];


export default prompts;