import {PROMPT_IDS} from "./consts/promptIds";
import {
    patientHistory,
    profilePhoto,
    userValidation
} from "./sharedSelfService";
import {yesNoPromptsOptions, yesNoUnknownPromptsOptions} from "./consts/promptOptions";
import {medicalConditionsWithNone} from "./consts/medicalConditions";
import {allergiesWithNone} from "./consts/allergies";
import {surgicalProceduresWithNone} from "./consts/surgicalProcedures";

export const allergies = (next = PROMPT_IDS.MEDICAL_CONDITIONS) => [
    {
        id: PROMPT_IDS.ALLERGIES,
        type: "autocomplete",
        section: "allergies",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please select your allergies, e.g. Latex\n\nNote: If not applicable, click 'None' and proceed\n";
                } else {
                    const {patient_first_name} = responses;
                    return `Please select ${patient_first_name}'s allergies, e.g. Latex\n\nNote: If not applicable, click 'None' and proceed\n`;
                }
            },
            options: allergiesWithNone,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            nullAlias: 'none',
            skipLabel: 'None'
        },
        onResponse: (response) => (!response) || response?.trim()?.toLowerCase() === 'none' ? next : PROMPT_IDS.MORE_ALLERGIES
    },
    {
        id: PROMPT_IDS.MORE_ALLERGIES,
        type: "option",
        section: "allergies",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you have another allergy?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} have another allergy?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.ALLERGIES : PROMPT_IDS.ALLERGIES_NOTE
    },
    {
        id: PROMPT_IDS.ALLERGIES_NOTE,
        type: "text",
        section: "allergies",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your allergies";
                } else {
                    const {patient_first_name} = responses;
                    return `Please let us know if there's anything else that we should note about ${patient_first_name}'s allergies`;
                }
            },
            optional: true,
        },
        onResponse: () => next
    },
    {
        // This is a bare field (never shown) which captures the patient_age (see PROMPT_IDS.IS_CONSENTING_AGE responseCallback)
        id: PROMPT_IDS.ALLERGIES_LIST,
        section: "allergies",
        onPayload: (responses, props, payload) => {
            // update the payload based on the responses of other fields
            const parsedResponses = responses[PROMPT_IDS.ALLERGIES].join(', ')
            payload['allergies'][PROMPT_IDS.ALLERGIES] = parsedResponses
        }
    }
];

export const medicalCondition = (next = PROMPT_IDS.SURGICAL_PROCEDURES) => [
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS,
        type: "autocomplete",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please select your medical conditions, e.g. Hypertension\n\nNote: If not applicable, click 'None' and proceed\n";
                } else {
                    const {patient_first_name} = responses;
                    return `Please select ${patient_first_name}'s medical conditions, e.g. Hypertension\n\nNote: If not applicable, click 'None' and proceed\n`;
                }
            },
            options: medicalConditionsWithNone,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            skipLabel: 'None'
        },
        onResponse: (response) => (!response) || response?.trim()?.toLowerCase() === 'none' ? next : PROMPT_IDS.MEDICAL_CONDITION_DATES
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITION_DATES,
        type: "date",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "When were you diagnosed with this condition?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    return "When was this condition diagnosed?\n\nNote: Select the date nearest to which the patient is able to identify";
                }
            },
            max: new Date(),
            optional: true,
            nullOnSkip: true,
            nullAlias: 'none',
            skipLabel: 'Unknown'
        },
        onResponse: (response) => PROMPT_IDS.MORE_MEDICAL_CONDITION
    },
    {
        id: PROMPT_IDS.MORE_MEDICAL_CONDITION,
        type: "option",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you have another medical condition?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} have another medical condition?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.MEDICAL_CONDITIONS : PROMPT_IDS.MEDICAL_CONDITIONS_NOTE
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS_NOTE,
        type: "text",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your medical conditions";
                } else {
                    const {patient_first_name} = responses;
                    return `Please let us know if there's anything else that we should note about ${patient_first_name}'s medical conditions`;
                }
            },
            optional: true,
        },
        onResponse: () => next
    },
    {
        // This is a bare field (never shown) which captures the patient_age (see PROMPT_IDS.IS_CONSENTING_AGE responseCallback)
        id: PROMPT_IDS.MEDICAL_CONDITIONS_LIST,
        section: "medical_conditions",
        onPayload: (responses, props, payload) => {
            // update the payload based on the responses of other fields
            const parsedResponses = responses[PROMPT_IDS.MEDICAL_CONDITIONS]?.map((item, index) => {
                if (!item || item === 'none') return {condition: 'None', date: null}
                return {
                    condition: item,
                    date: responses[PROMPT_IDS.MEDICAL_CONDITION_DATES][index]
                }
            })

            payload['medical_conditions'][PROMPT_IDS.MEDICAL_CONDITIONS_LIST] = parsedResponses

            delete payload['medical_conditions'][PROMPT_IDS.MEDICAL_CONDITIONS]
            delete payload['medical_conditions'][PROMPT_IDS.MEDICAL_CONDITION_DATES]
        }
    }
];

export const surgicalProcedures = (next = PROMPT_IDS.CHRONIC_MEDICATIONS) => [
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURES,
        type: "autocomplete",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please select your surgical procedures\n\nNote: If not applicable, click 'None' and proceed\n";
                } else {
                    const {patient_first_name} = responses;
                    return `Please select ${patient_first_name}'s surgical procedures\n\nNote: If not applicable, click 'None' and proceed\n`;
                }
            },
            options: surgicalProceduresWithNone,
            placeholder: "Select",
            freeSolo: true,
            optional: true,
            nullOnSkip: true,
            nullAlias: 'none',
            skipLabel: 'None'
        },
        onResponse: (response) => (!response) || response?.trim()?.toLowerCase() === 'none' ? next : PROMPT_IDS.SURGICAL_PROCEDURE_DATES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_DATES,
        type: "date",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "When did you have this procedure done?\n\nNote: Select the date nearest to which you are able to identify";
                } else {
                    const {patient_first_name} = responses;
                    return `When did ${patient_first_name} have this procedure done?\n\nNote: Select the date nearest to which ${patient_first_name} is able to identify`;
                }
            },
            max: new Date(),
            optional: true,
            nullOnSkip: true,
            nullAlias: 'none',
            skipLabel: 'Unknown'
        },
        onResponse: (response) => PROMPT_IDS.MORE_SURGICAL_PROCEDURE
    },
    {
        id: PROMPT_IDS.MORE_SURGICAL_PROCEDURE,
        type: "option",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Have you had another procedure done?";
                } else {
                    const {patient_first_name} = responses;
                    return `Has ${patient_first_name} had another procedure done?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.SURGICAL_PROCEDURES : PROMPT_IDS.SURGICAL_PROCEDURE_NOTES
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_NOTES,
        type: "text",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your surgical procedures";
                } else {
                    const {patient_first_name} = responses;
                    return `Please let us know if there's anything else that we should note about ${patient_first_name}'s surgical procedures`;
                }
            },
            optional: true,
        },
        onResponse: () => next
    },
    {
        // This is a bare field (never shown) which captures the patient_age (see PROMPT_IDS.IS_CONSENTING_AGE responseCallback)
        id: PROMPT_IDS.SURGICAL_PROCEDURES_LIST,
        section: "surgical_procedures",
        onPayload: (responses, props, payload) => {
            // update the payload based on the responses of other fields
            const parsedResponses = responses[PROMPT_IDS.SURGICAL_PROCEDURES].map((item, index) => {
                if (!item || item === 'none') return {procedure: 'None', date: null}
                return {
                    procedure: item,
                    date: responses[PROMPT_IDS.SURGICAL_PROCEDURE_DATES][index]
                }
            })

            payload['surgical_procedures'][PROMPT_IDS.SURGICAL_PROCEDURES_LIST] = parsedResponses

            delete payload['surgical_procedures'][PROMPT_IDS.SURGICAL_PROCEDURES]
            delete payload['surgical_procedures'][PROMPT_IDS.SURGICAL_PROCEDURE_DATES]
        }
    }
];

export const chronicMedication = (next = PROMPT_IDS.BLOOD_CLOTTING_DISORDER) => [
    {
        id: PROMPT_IDS.CHRONIC_MEDICATIONS,
        type: "text",
        section: "chronic_medication",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please enter all your chronic medications\n\nNote: If not applicable, click 'None' and proceed\n";
                } else {
                    const {patient_first_name} = responses;
                    return `Please enter all ${patient_first_name}'s chronic medications\n\nNote: If not applicable, click 'None' and proceed\n`;
                }
            },
            options: yesNoPromptsOptions,
            optional: true,
            nullOnSkip: true,
            nullAlias: 'none',
            skipLabel: 'None'
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.CHRONIC_MEDICATION_NOTES : next
    },
    {
        id: PROMPT_IDS.CHRONIC_MEDICATION_NOTES,
        type: "text",
        section: "chronic_medication",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please let us know if there's anything else that we should note about your allergies"
                } else {
                    const {patient_first_name} = responses;
                    return `"Please let us know if there's anything else that we should note about ${patient_first_name}'s allergies`;
                }
            },
            optional: true,
        },
        onResponse: () => next
    }
];

const dentistryPrompts = [
    {
        id: PROMPT_IDS.BLOOD_CLOTTING_DISORDER,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Any blood clotting disorder?";
                }

                return `Does ${patient_first_name} have any blood clotting disorder?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: (response, id, ctrl) => {
            const {responses} = ctrl.state;
            const gender = responses[PROMPT_IDS.GENDER];
            if (gender === "female") {
                return PROMPT_IDS.PREGNANT;
            } else {
                return PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS;
            }
        }
    }, {
        id: PROMPT_IDS.PREGNANT,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Are you pregnant?";
                }
                return `Is ${patient_first_name} pregnant?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.BIRTH_CONTROL_PILL
    }, {
        id: PROMPT_IDS.BIRTH_CONTROL_PILL,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Are you on the pill?";
                }

                return `Is ${patient_first_name} on the pill?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS
    }, {
        id: PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you use any medication and/or herbal preparations?";
                }
                return `Does ${patient_first_name} presently use any medication and/or herbal preparations?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS_NOTE : PROMPT_IDS.SMOKING
    }, {
        id: PROMPT_IDS.PRESENT_MEDICATION_HERBAL_PREPARATIONS_NOTE,
        type: "text",
        section: "dentistry",
        config: {
            message: "Please list the medication and/or herbal preparations",
        },
        onResponse: () => PROMPT_IDS.SMOKING
    }, {
        id: PROMPT_IDS.SMOKING,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you smoke?";
                }
                return `Does ${patient_first_name} smoke?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.CORTISONE_THERAPY
    }, {
        id: PROMPT_IDS.CORTISONE_THERAPY,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you had cortisone therapy past or present?";
                }
                return `Has ${patient_first_name} had cortisone therapy past or present?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.ABNORMAL_REACTIONS_DENTAL_INJECTIONS
    }, {
        id: PROMPT_IDS.ABNORMAL_REACTIONS_DENTAL_INJECTIONS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you experienced any abnormal reactions with dental injections?";
                }
                return `Has ${patient_first_name} experienced any abnormal reactions with dental injections?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.GUMS_BLEED
    }, {
        id: PROMPT_IDS.GUMS_BLEED,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do your gums bleed?";
                }
                return `Does ${patient_first_name} gums bleed?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.FREQUENT_MOUTH_ULCERS
    }, {
        id: PROMPT_IDS.FREQUENT_MOUTH_ULCERS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you have frequent ulcers in your mouth?";
                }
                return `Does ${patient_first_name} have frequent ulcers in their mouth?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.LOOSE_TEETH
    }, {
        id: PROMPT_IDS.LOOSE_TEETH,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you noticed any loose teeth?";
                }

                return `Has ${patient_first_name} noticed any loose teeth?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.MOUTH_HABITS
    }, {
        id: PROMPT_IDS.MOUTH_HABITS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Are you conscious of any mouth habits, e.g. clenching or grinding?";
                }
                return `Is ${patient_first_name} conscious of any mouth habits, e.g. clenching or grinding?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.JAW_JOINT_PAIN
    }, {
        id: PROMPT_IDS.JAW_JOINT_PAIN,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Do you experience pain in the joints of your jaw?";
                }
                return `Does ${patient_first_name} experience pain in the joints of their jaw?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.GUM_TREATMENTS_OPERATIONS
    }, {
        id: PROMPT_IDS.GUM_TREATMENTS_OPERATIONS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you had any gum treatments or operations?";
                }
                return `Has ${patient_first_name} had any gum treatments or operations?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.ABNORMAL_BLEEDING_EXTRACTIONS
    }, {
        id: PROMPT_IDS.ABNORMAL_BLEEDING_EXTRACTIONS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you experienced any abnormal bleeding after extractions?";
                }
                return `Has ${patient_first_name} experienced any abnormal bleeding after extractions?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.ORTHODONTIC_TREATMENT
    }, {
        id: PROMPT_IDS.ORTHODONTIC_TREATMENT,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Have you had orthodontic treatment?";
                }

                return `Has ${patient_first_name} had orthodontic treatment?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.OTHER_HEALTH_PROBLEMS
    }, {
        id: PROMPT_IDS.OTHER_HEALTH_PROBLEMS,
        type: "option",
        section: "dentistry",
        config: {
            message: ({for_who, patient_first_name}) => {
                if (for_who === "myself") {
                    return "Any other health problems?";
                }

                return `Does ${patient_first_name} have any other health problems?`;
            },
            options: yesNoUnknownPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.OTHER_HEALTH_PROBLEMS_NOTE : null
    }, {
        id: PROMPT_IDS.OTHER_HEALTH_PROBLEMS_NOTE,
        type: "text",
        section: "dentistry",
        config: {
            message: "Please list the other health problems",
        },
        onResponse: () => null
    }
]


const prompts = [
    // Patient Information
    ...userValidation,
    ...profilePhoto,
    ...patientHistory,

    // Medical and Contextual History
    ...allergies(PROMPT_IDS.MEDICAL_CONDITIONS),
    ...medicalCondition(PROMPT_IDS.SURGICAL_PROCEDURES),
    ...surgicalProcedures(PROMPT_IDS.CHRONIC_MEDICATIONS),
    ...chronicMedication(PROMPT_IDS.BLOOD_CLOTTING_DISORDER),

    // Dentistry Specific
    ...dentistryPrompts,
];

export default prompts;