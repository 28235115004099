import TermsAndConditions from "../components/TermsAndConditions";
import TermsContent from "./consts/TermsContent";
import {medicalConditionsWithNone as medicalConditionsList} from "./consts/medicalConditions";
import {PROMPT_IDS} from "./consts/promptIds";
import {
    acceptDeclinePromptsOptions, confirmCancelPromptsOptions, consentAges,
    forWhoPromptsOptions,
    patientGenderPromptsOptions,
    yesNoPromptsOptions, yesNoStoppedPromptsOptions
} from "./consts/promptOptions";

const welcomeText = `Welcome to the future of healthcare!

You can complete key information ahead of your medical consultation, right here!
`;

export const initialPrompt = PROMPT_IDS.FOR_WHO;

export const userValidation = [
    {
        id: PROMPT_IDS.WELCOME,
        type: "option",
        section: "user_validation",
        config: {
            message: welcomeText,
            options: [{id: "start", text: "Get started"}]
        },
        onResponse: (response) => PROMPT_IDS.FOR_WHO
    },
    {
        id: PROMPT_IDS.FOR_WHO,
        type: "option",
        section: "user_validation",
        config: {
            message: "Who are you completing this information for?",
            options: forWhoPromptsOptions
        },
        onResponse: (response) => PROMPT_IDS.PATIENT_FIRST_NAME
    },
    {
        id: PROMPT_IDS.PATIENT_FIRST_NAME,
        type: "text",
        section: "user_validation",
        config: {
            message: "What is the patient's first name?"
        },
        onResponse: (response, id, ctrl) => {
            const {responses} = ctrl.state;
            const isForMySelf = responses[PROMPT_IDS.FOR_WHO] === "myself";
            return isForMySelf ? PROMPT_IDS.ACCEPT_TERMS_CONDITIONS : PROMPT_IDS.PATIENT_RELATIONSHIP;
        }
    },
    {
        id: PROMPT_IDS.PATIENT_RELATIONSHIP,
        type: "text",
        section: "user_validation",
        config: {
            message: "Please provide your relationship to the patient."
        },
        onResponse: () => PROMPT_IDS.SUBMITTER_NAME
    },
    {
        id: PROMPT_IDS.SUBMITTER_NAME,
        type: "text",
        section: "user_validation",
        config: {
            message: "What is your name?"
        },
        onResponse: () => PROMPT_IDS.ACCEPT_TERMS_CONDITIONS
    },
    {
        id: PROMPT_IDS.ACCEPT_TERMS_CONDITIONS,
        type: "option",
        section: "user_validation",
        config: {
            message: responses => {
                // const isForMySelf = responses[PROMPT_IDS.FOR_WHO] === "myself";
                return <TermsAndConditions content={<TermsContent/>} />
            },
            options: acceptDeclinePromptsOptions
        },
        onResponse: (response, id, ctrl) => {
            if (response !== "accept") {
                ctrl.clearHistory();
                return PROMPT_IDS.WELCOME;
            } else {
                const {responses} = ctrl.state;
                const isForMySelf = responses[PROMPT_IDS.FOR_WHO] === "myself";
                return isForMySelf ? PROMPT_IDS.IS_CONSENTING_AGE : PROMPT_IDS.PATIENT_ACCEPT_TERMS_CONDITIONS;
            }
        }
    },
    {
        id: PROMPT_IDS.PATIENT_ACCEPT_TERMS_CONDITIONS,
        type: "option",
        section: "user_validation",
        config: {
            message: ({for_who, patient_first_name}) => {
                const isForMySelf = for_who === "myself";
                return <TermsAndConditions content={<TermsContent/>} myself={isForMySelf} patientName={patient_first_name}/>
                // return `Please confirm that you accept the terms of service on behalf of ${patient_first_name} and they have granted you permission to complete information on their behalf`
            },
            options: confirmCancelPromptsOptions
        },
        onResponse: (response, id, ctrl) => {
            if (response !== "confirm") {
                ctrl.clearHistory();
                return PROMPT_IDS.WELCOME;
            } else {
                return PROMPT_IDS.IS_CONSENTING_AGE;
            }
        }
    },
    {
        id: PROMPT_IDS.IS_CONSENTING_AGE,
        type: "option",
        section: "user_validation",
        config: {
            message: (responses, messageProps) => {
                const {for_who} = responses;
                const {type} = messageProps

                const age = type === 'dentistry' ? '18' : '12'

                if (for_who === "myself") {
                    return `To help us complete the right information for you, please confirm that you are ${age} years or older?`
                } else {
                    const {patient_first_name} = responses;
                    return `To help us complete the right information, please confirm that ${patient_first_name} is ${age} years or older?`
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: () => {
            return PROMPT_IDS.UPDATE_PROFILE_PHOTO
        },
    },
    {
        // This is a bare field (never shown) which captures the patient_age (see PROMPT_IDS.IS_CONSENTING_AGE responseCallback)
        id: PROMPT_IDS.PATIENT_AGE,
        section: "user_validation",
        onPayload: (responses, props, payload) => {
            // update the payload based on the responses of other fields
            const {type} = props
            const age = consentAges[type]?.[responses[PROMPT_IDS.IS_CONSENTING_AGE]] ?? 'Unknown'
            payload['user_validation'][PROMPT_IDS.PATIENT_AGE] = age
        }
    }
];

export const profilePhoto = [
    {
        id: PROMPT_IDS.UPDATE_PROFILE_PHOTO,
        type: "camera",
        section: "profile_photo",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Would you like to update your profile photo?\n\nNote: Please remove your mask when taking your photo";
                } else {
                    const {patient_first_name} = responses;
                    return `Would you like to update ${patient_first_name}'s profile photo?\n\nNote: Please remove your mask when taking your photo`;
                }
            },
            optional: true
        },
        onResponse: (response) => PROMPT_IDS.UPDATE_MEDICAL_HISTORY
    }
];

export const patientHistory = [
    {
        id: PROMPT_IDS.UPDATE_MEDICAL_HISTORY,
        type: "option",
        section: "patient_history",
        config: {
            message: (responses, messageProps) => {
                const {for_who} = responses;
                const {type} = messageProps

                if (for_who === "myself" && type === "medical") {
                    return "Would you like to update your health record information now?\n\nNote: This could take between 5 - 10 minutes to complete. You could return to this website to complete this information at any time.";
                } else if (for_who === "myself" && type === "dentistry") {
                    return `Please update your medical history now.`;
                } else if (for_who !== "myself" && type === "medical") {
                    const {patient_first_name} = responses;
                    return `Would you like to update ${patient_first_name}'s health record information now?\n\nNote: This could take between 5 - 10 minutes to complete. You could return to this website to complete this information at any time.`;
                } else if (for_who !== "myself" && type === "dentistry") {
                    const {patient_first_name} = responses;
                    return `Please update ${patient_first_name}'s medical history now.`;
                }
            },
            options: yesNoPromptsOptions
        },
        onResponse: (response, id, ctrl) => {
            if (response === "yes") {
                const {responses} = ctrl.state;
                const isConsentingAge = responses[PROMPT_IDS.IS_CONSENTING_AGE] === "yes";

                return isConsentingAge ? PROMPT_IDS.GENDER : PROMPT_IDS.ALLERGIES
            } else {
                return null;
            }

        }
    },
    {
        id: PROMPT_IDS.GENDER,
        type: "option",
        section: "user_validation",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please select your gender at birth to help us capture the correct information for you";
                } else {
                    const {patient_first_name} = responses;
                    return `Please select ${patient_first_name}'s gender at birth to help us capture the correct information`;
                }
            },
            options: patientGenderPromptsOptions
        },
        onResponse: (response) => PROMPT_IDS.ALLERGIES
    }
];

export const allergies = (next = PROMPT_IDS.DISABILITIES) => [
    {
        id: PROMPT_IDS.ALLERGIES,
        type: "option",
        section: "allergies",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "Have you ever taken a medication/food/been in contact with something, " +
                        "that caused a reaction of the following: anaphylaxis, shortness of breath, " +
                        "hives, itching, angioedema (swelling of the skin eg face), rash/other serious skin reaction?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `Has ${patient_first_name} ever taken a medication/food/been in contact with something, ` +
                        "that caused a reaction of the following: anaphylaxis, shortness of breath, " +
                        "hives, itching, angioedema (swelling of the skin eg face), rash/other serious skin reaction?"
                    )
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response, id, ctrl) => {
            console.log(ctrl)
            return response === "yes" ? PROMPT_IDS.ALLERGIES_NOTE : next
        }
    },
    {
        id: PROMPT_IDS.ALLERGIES_NOTE,
        type: "text",
        section: "allergies",
        config: {
            message: "Please provide what medication(s)/food/other(s) caused this reaction?",
        },
        onResponse: () => next
    }
];

export const disabilities = [
    {
        id: PROMPT_IDS.DISABILITIES,
        type: "option",
        section: "disabilities",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you have any disabilities? (Visual, Hearing, Physical or other?)";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} have any disabilities? (Visual, Hearing, Physical or other?)`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.DISABILITIES_NOTE : PROMPT_IDS.MEDICAL_CONDITIONS
    },
    {
        id: PROMPT_IDS.DISABILITIES_NOTE,
        type: "text",
        section: "disabilities",
        config: {
            message: "Please provide details of the disabilities",
        },
        onResponse: () => PROMPT_IDS.MEDICAL_CONDITIONS
    }
];

export const medicalCondition = [
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS,
        type: "option",
        section: "medical_conditions",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you have any medical condition(s)?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} have any medical condition(s)?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.MEDICAL_CONDITIONS_NOTE : PROMPT_IDS.SURGICAL_PROCEDURES
    },
    {
        id: PROMPT_IDS.MEDICAL_CONDITIONS_NOTE,
        type: "text",
        section: "medical_conditions",
        config: {
            message: "Please provide details of the medical condition(s)"
        },
        onResponse: () => PROMPT_IDS.SURGICAL_PROCEDURES
    }
];

export const surgicalProcedures = [
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURES,
        type: "option",
        section: "surgical_procedures",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Have you had any surgical procedure(s) done?";
                } else {
                    const {patient_first_name} = responses;
                    return `Has ${patient_first_name} had any surgical procedure(s) done?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.SURGICAL_PROCEDURE_NOTES : PROMPT_IDS.CHRONIC_MEDICATIONS
    },
    {
        id: PROMPT_IDS.SURGICAL_PROCEDURE_NOTES,
        type: "text",
        section: "surgical_procedures",
        config: {
            message: "Please provide details of the surgical procedure(s)",
        },
        onResponse: () => PROMPT_IDS.CHRONIC_MEDICATIONS
    }
];

export const chronicMedication = (next = PROMPT_IDS.SMOKING) => [
    {
        id: PROMPT_IDS.CHRONIC_MEDICATIONS,
        type: "option",
        section: "chronic_medication",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Are you taking any medication(s) or supplement(s)?";
                } else {
                    const {patient_first_name} = responses;
                    return `Is ${patient_first_name} taking any medication(s) or supplement(s)?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.CHRONIC_MEDICATION_NOTES : next
    },
    {
        id: PROMPT_IDS.CHRONIC_MEDICATION_NOTES,
        type: "text",
        section: "chronic_medication",
        config: {
            message: "Please provide details of the medication(s) or supplement(s)?",
        },
        onResponse: () => next
    }
];

export const lifestyleHistory = [
    {
        id: PROMPT_IDS.SMOKING,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you smoke?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} smoke?`;
                }
            },
            options: yesNoStoppedPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.SMOKING_CIGARETTES_PER_DAY : PROMPT_IDS.VAPING
    },
    {
        id: PROMPT_IDS.SMOKING_CIGARETTES_PER_DAY,
        type: "text",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "How many cigarettes do you smoke per day?";
                } else {
                    const {patient_first_name} = responses;
                    return `How many cigarettes does ${patient_first_name} smoke per day?`;
                }
            },
        },
        onResponse: () => PROMPT_IDS.SMOKING_HOW_MANY_YEARS
    },
    {
        id: PROMPT_IDS.SMOKING_HOW_MANY_YEARS,
        type: "text",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "How many years have you been smoking?";
                } else {
                    const {patient_first_name} = responses;
                    return `How many years has ${patient_first_name} been smoking?`;
                }
            },
        },
        onResponse: () => PROMPT_IDS.VAPING
    },
    {
        id: PROMPT_IDS.VAPING,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you vape?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} vape?`;
                }
            },
            options: yesNoStoppedPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.DRINK_ALCOHOL
    },
    {
        id: PROMPT_IDS.DRINK_ALCOHOL,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you drink alcohol?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} drink alcohol?`;
                }
            },
            options: yesNoStoppedPromptsOptions,
        },
        onResponse: (response) => PROMPT_IDS.RECREATIONAL_SUBSTANCES
    },
    {
        id: PROMPT_IDS.RECREATIONAL_SUBSTANCES,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you use any recreational substances?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} use any recreational substances?`;
                }
            },
            options: yesNoStoppedPromptsOptions,
        },
        onResponse: (response) => PROMPT_IDS.EXERCISE
    },
    {
        id: PROMPT_IDS.EXERCISE,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you exercise?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} exercise?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response, id, ctrl) => response === "yes" ? PROMPT_IDS.EXERCISE_MINUTES_PER_WEEK : PROMPT_IDS.SPECIFIC_DIET
    },
    {
        id: PROMPT_IDS.EXERCISE_MINUTES_PER_WEEK,
        type: "text",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "How many minutes do you exercise for on average per week?";
                } else {
                    const {patient_first_name} = responses;
                    return `How many minutes does ${patient_first_name} exercise for on average per week?`;
                }
            },
        },
        onResponse: () => PROMPT_IDS.SPECIFIC_DIET
    },
    {
        id: PROMPT_IDS.SPECIFIC_DIET,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you follow a specific diet?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} follow a specific diet?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response, id, ctrl) => response === "yes" ? PROMPT_IDS.SPECIFIC_DIET_DETAILS : PROMPT_IDS.SLEEPING_PROBLEMS
    },
    {
        id: PROMPT_IDS.SPECIFIC_DIET_DETAILS,
        type: "text",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please provide detail of your diet?";
                } else {
                    const {patient_first_name} = responses;
                    return `Please provide detail of ${patient_first_name}'s diet?`;
                }
            },
        },
        onResponse: () => PROMPT_IDS.SLEEPING_PROBLEMS
    },
    {
        id: PROMPT_IDS.SLEEPING_PROBLEMS,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you have sleeping problems?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} have sleeping problems?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response, id, ctrl) => response === "yes" ? PROMPT_IDS.SLEEPING_PROBLEMS_DETAILS : PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS
    },
    {
        id: PROMPT_IDS.SLEEPING_PROBLEMS_DETAILS,
        type: "option",
        section: "lifestyle_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Do you use sleeping tablets for this?";
                } else {
                    const {patient_first_name} = responses;
                    return `Does ${patient_first_name} use sleeping tablets for this?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: () => PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS
    },
];

export const familyHistory = (next = PROMPT_IDS.OCCUPATIONAL_AND_SOCIAL_HISTORY) => [
    {
        id: PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS,
        type: "option",
        section: "family_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Are there genetic conditions present in your direct family (blood) relatives?";
                } else {
                    const {patient_first_name} = responses;
                    return `Are there genetic conditions present in ${patient_first_name}'s direct family (blood) relatives?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS_LIST : next
    },
    {
        id: PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS_LIST,
        type: "autocomplete",
        section: "family_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Please select the medical conditions that are prevalent in your family";
                } else {
                    const {patient_first_name} = responses;
                    return `Please select the medical conditions that are prevalent in ${patient_first_name}'s family`;
                }
            },
            options: medicalConditionsList,
            placeholder: "Select",
            freeSolo: true,
        },
        onResponse: (response) => response === undefined ? next : PROMPT_IDS.MORE_FAMILY_MEDICAL_CONDITION
    },
    {
        id: PROMPT_IDS.MORE_FAMILY_MEDICAL_CONDITION,
        type: "option",
        section: "family_history",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return "Are there any other conditions that are prevalent in your direct family?";
                } else {
                    const {patient_first_name} = responses;
                    return `Are there any other conditions that are prevalent in ${patient_first_name}'s family?`;
                }
            },
            options: yesNoPromptsOptions,
        },
        onResponse: (response) => response === "yes" ? PROMPT_IDS.FAMILY_MEDICAL_CONDITIONS_LIST : next
    },
];

export const personalInformation = [
    {
        id: PROMPT_IDS.OCCUPATIONAL_AND_SOCIAL_HISTORY,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "What do you do for a living?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `What does ${patient_first_name} do for a living?`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => PROMPT_IDS.FAMILY_STRUCTURE
    },
    {
        id: PROMPT_IDS.FAMILY_STRUCTURE,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "Briefly describe your family structure, including whether you are in a relationship and whom you live with."
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `Briefly describe ${patient_first_name}'s family structure, including whether they are in a relationship and whom they live with.`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => PROMPT_IDS.SUPPORT_STRUCTURE
    },
    {
        id: PROMPT_IDS.SUPPORT_STRUCTURE,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "Who is your support person?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `Who is ${patient_first_name}'s support person?`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => PROMPT_IDS.SPIRITUALITY
    },
    {
        id: PROMPT_IDS.SPIRITUALITY,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "What is your religion/spirituality?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `What is ${patient_first_name}'s religion/spirituality?`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => PROMPT_IDS.HOBBIES
    },
    {
        id: PROMPT_IDS.HOBBIES,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "Do you have any hobbies?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `Does ${patient_first_name} have any hobbies?`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => PROMPT_IDS.GENERAL
    },
];

export const generalInformation = (next = null) => [
    {
        id: PROMPT_IDS.GENERAL,
        type: "text",
        section: "personal_information",
        config: {
            message: (responses) => {
                const {for_who} = responses;

                if (for_who === "myself") {
                    return (
                        "Is there anything else you would like your doctor to know, e.g. is there anything that is causing you stress?"
                    )
                } else {
                    const {patient_first_name} = responses;
                    return (
                        `Is there anything else ${patient_first_name} would like their doctor to know, e.g. is there anything that is causing them stress?`
                    )
                }
            },
            optional: true,
        },
        onResponse: () => next
    },
]