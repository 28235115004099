import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    root: {
        padding: "2em",
        fontSize: "1.2em",
        color: "#293754",
    }
}));

export const backdropStyles = makeStyles(theme => ({
    root: {
        fontFamily: 'ProximaNova',
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        justifyContent: 'center'
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'auto min(700px, 100%) auto',
        gridTemplateRows: 'auto auto minmax(60%, 650px) 10% 10%',
        padding: '36px',
        height: '75%'
    },
    title: {
        textAlign: 'center',
        gridRow: '1 / 1',
        gridColumn: '1 / span 3',
        fontSize: 'clamp(2rem, 6vmin, 5rem)',
        fontWeight: '600',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    subtitle: {
        textAlign: 'center',
        gridRow: '2 / 2',
        gridColumn: '1 / span 3',
        fontSize: 'clamp(1rem, 2.5vmin, 2rem)',
        fontWeight: '400',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '800px',
        margin: 'auto',
    },
    people: {
        textAlign: 'center',
        gridRow: '3 / 3',
        gridColumn: '2 / span 1',
        height: '100%',
        width: '100%'
    },
    button: {
        textAlign: 'center',
        fontSize: '2.25vh',
        fontWeight: '600',
        border: 'none',
        borderRadius: '2.25vh',
        color: 'white',
        marginBottom: '10px',
        background: '#2c3b79',
        '&:hover': {
            filter: 'brightness(1.5)'
        }
    },
    button_one: {
        padding: '0px 1rem',
        gridRow: '4 / 4',
        gridColumn: '2 / span 1',
    },
    button_two: {
        padding: '0px 1rem',
        gridRow: '5 / 5',
        gridColumn: '2 / span 1',
    },
    background: {
        position: 'absolute', 
        zIndex: '-1',
        top: '-2%',
        width: '100%', 
        height: '70%', 
    },
}));

export default useStyles;