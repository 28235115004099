export const PROMPT_IDS = {
    WELCOME: "welcome",
    FOR_WHO: "for_who",
    ACCEPT_TERMS_CONDITIONS: "accept_terms_conditions",
    PATIENT_FIRST_NAME: "patient_first_name",
    PATIENT_RELATIONSHIP: "patient_relationship",
    PATIENT_ACCEPT_TERMS_CONDITIONS: "patient_accept_terms_conditions",
    SUBMITTER_NAME: "submitter_name",
    IS_CONSENTING_AGE: "is_consenting_age",
    PATIENT_AGE: "patient_age",
    TRAVELLED_INTERNATIONALLY: "travelled_internationally",
    IS_HEALTHCARE_WORKER: "is_healthcare_worker",
    HEALTHCARE_WORKER_TRAVELLING: "healthcare_worker_travelling",
    TRAVEL_REASON: "travel_reason",
    IN_CONTACT_WITH_ILL_PERSON: "in_contact_with_ill_person",
    ATTENDED_FUNERAL: "attended_funeral",
    TRAVELLING_RSA: "travelling_rsa",
    HAS_FEVER: "has_fever",
    TEMPERATURE: "temperature",
    TRAVELLING_MALARIA: "travelling_malaria",
    UPDATE_PROFILE_PHOTO: "update_profile_photo",
    UPDATE_MEDICAL_HISTORY: "update_medical_history",
    GENDER: "gender",
    ALLERGIES: "allergies",
    MORE_ALLERGIES: "more_allergies",
    ALLERGIES_NOTE: "allergies_note",
    DISABILITIES: "disabilities",
    MORE_DISABILITIES: "more_disabilities",
    DISABILITIES_NOTE: "disabilities_note",
    MEDICAL_CONDITIONS: "medical_conditions",
    MEDICAL_CONDITION_DATES: "medical_condition_dates",
    MORE_MEDICAL_CONDITION: "more_medical_condition",
    MEDICAL_CONDITIONS_NOTE: "medical_conditions_note",
    MEDICAL_CONDITIONS_LIST: "medical_conditions_list",
    SURGICAL_PROCEDURES: "surgical_procedures",
    SURGICAL_PROCEDURE_DATES: "surgical_procedure_dates",
    MORE_SURGICAL_PROCEDURE: "more_surgical_procedure",
    SURGICAL_PROCEDURE_NOTES: "surgical_procedure_notes",
    SURGICAL_PROCEDURES_LIST: "surgical_procedures_list",
    CHRONIC_MEDICATIONS: "chronic_medications",
    MORE_CHRONIC_MEDICATION: "more_chronic_medication",
    CHRONIC_MEDICATION_NOTES: "chronic_medication_notes",
    FAMILY_MEDICAL_CONDITIONS: "family_medical_conditions",
    FAMILY_MEDICAL_CONDITIONS_LIST: "family_medical_conditions_list",
    MORE_FAMILY_MEDICAL_CONDITION: "more_family_medical_condition",
    FAMILY_MEDICAL_CONDITION_NOTES: "family_medical_condition_notes",
    VACCINES: "vaccines",
    VACCINE_DATES: "vaccine_dates",
    MORE_VACCINE: "more_vaccine",
    SMOKING : "smoking",
    SMOKING_CIGARETTES_PER_DAY: "smoking_cigarettes_per_day",
    SMOKING_HOW_MANY_YEARS: "smoking_how_many_years",
    VAPING : "vaping",
    DRINK_ALCOHOL: "drink_alcohol",
    RECREATIONAL_SUBSTANCES: "recreational_substances",
    EXERCISE: "exercise",
    EXERCISE_MINUTES_PER_WEEK: "exercise_minutes_per_week",
    SPECIFIC_DIET: "specific_diet",
    SPECIFIC_DIET_DETAILS: "specific_diet_details",
    SLEEPING_PROBLEMS: "sleeping_problems",
    SLEEPING_PROBLEMS_DETAILS: "sleeping_problems_details",
    HAVE_CHILDREN: "have_children",
    NUMBER_OF_CHILDREN: "number_of_children",
    METHOD_OF_DELIVERY: "method_of_delivery",
    DELIVERY_COMPLICATIONS: "delivery_complications",
    HAD_MISCARRIAGES: "had_miscarriages",
    HAD_ECTOPICS: "had_ectopics",
    FAMILY_PLANNING_INTERVENTIONS: "family_planning_interventions",
    LAST_PAP_SMEAR_DATE: "last_pap_smear_date",
    LAST_MAMMOGRAM_DATE: "last_mammogram_date",
    LAST_PROSTATE_EXAM_DATE: "last_prostate_exam_date",
    LAST_PROSTATE_EXAM_RESULT: "last_prostate_exam_result",
    LAST_PSA_TEST_DATE: "last_psa_test_date",
    LAST_PSA_TEST_RESULT: "last_psa_test_result",

    // Dentistry prompts
    //////////////////////
    BLOOD_CLOTTING_DISORDER: "blood_clotting_disorder",
    CORTISONE_THERAPY: "cortisone_therapy",
    PRESENT_MEDICATION_HERBAL_PREPARATIONS: "present_medication_herbal_preparations",
    PRESENT_MEDICATION_HERBAL_PREPARATIONS_NOTE: "present_medication_herbal_preparations_note",
    ABNORMAL_REACTIONS_DENTAL_INJECTIONS: "abnormal_reactions_dental_injections",
    GUMS_BLEED: "gums_bleed",
    FREQUENT_MOUTH_ULCERS: "frequent_mouth_ulcers",
    LOOSE_TEETH: "loose_teeth",
    MOUTH_HABITS: "mouth_habits",
    JAW_JOINT_PAIN: "jaw_joint_pain",
    GUM_TREATMENTS_OPERATIONS: "gum_treatments_operations",
    ABNORMAL_BLEEDING_EXTRACTIONS: "abnormal_bleeding_extractions",
    ORTHODONTIC_TREATMENT: "orthodontic_treatment",
    OTHER_HEALTH_PROBLEMS: "other_health_problems",
    OTHER_HEALTH_PROBLEMS_NOTE: "other_health_problems_note",
    PREGNANT: "pregnant",
    BIRTH_CONTROL_PILL: "birth_control_pill",

    // Updated history prompts
    //////////////////////
    OCCUPATIONAL_AND_SOCIAL_HISTORY: "occupational_and_social_history",
    FAMILY_STRUCTURE: "family_structure",
    SUPPORT_STRUCTURE: "support_structure",
    SPIRITUALITY: "spirituality",
    HOBBIES: "hobbies",
    GENERAL: "general",

}