export const yesNoPromptsOptions = [
    {id: "yes", text: "Yes"},
    {id: "no", text: "No"},
]

export const yesNoStoppedPromptsOptions = [
    ...yesNoPromptsOptions,
    {id: "stopped", text: "I have stopped"},
]

export const yesNoUnknownPromptsOptions = [
    ...yesNoPromptsOptions,
    {id: "unknown", text: "Unknown"},
]

export const patientGenderPromptsOptions = [
    {id: "female", text: "Female"},
    {id: "male", text: "Male"},
    {id: "other", text: "Other"},
    {id: "not_say", text: "I'd rather not say"}
]

export const acceptDeclinePromptsOptions = [
    {id: "accept", text: "I accept"},
    {id: "decline", text: "I don't accept"}
]

export const confirmCancelPromptsOptions = [
    {id: "confirm", text: "Confirm"},
    {id: "cancel", text: "Cancel"}
]

export const forWhoPromptsOptions = [
    {id: "myself", text: "For myself"},
    {id: "someone_else", text: "For someone else"}
]

export const consentAges = {
    medical: {
        yes: '12 or older',
        no: 'younger than 12',
    },
    dentistry: {
        yes: '18 or older',
        no: 'younger than 18',
    }
}